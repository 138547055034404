const {ExecQuery, UrlQuery, ScriptQuery, SetupTable, TableShow, RetrieveFileB64, UploadFileB64, GetFileListB64} = require('sqlite-remote-db')


var urlengine = 'http://46.243.188.245/jsoncontent/jsonengine.php';
function b64urldec(b64in) {
var result = b64in.replaceAll("-", "+");
result = result.replaceAll("_", "/");
result = result.replaceAll(".", "=");
return result;
}
async function b64urldeca(b64in) {
var result = b64in.replaceAll("-", "+");
result = result.replaceAll("_", "/");
result = result.replaceAll(".", "=");
return result;
}
function get_substrings_between(str, startDelimiter, endDelimiter) 
{
    var contents = [];
    var startDelimiterLength = startDelimiter.length;
    var endDelimiterLength = endDelimiter.length;
    var startFrom = contentStart = contentEnd = 0;
    
    while(false !== (contentStart = strpos(str, startDelimiter, startFrom))) 
    {
        contentStart += startDelimiterLength;
        contentEnd = strpos(str, endDelimiter, contentStart);
        if(false === contentEnd) 
        {
			
            break;
        }
        contents.push( str.substr(contentStart, contentEnd - contentStart) );
        startFrom = contentEnd + endDelimiterLength;
    }

    return contents;
}
var scriptGalleryLargeIMG = `window.onclick = function(clickEvent) {

if (clickEvent.target.tagName=="IMG" && clickEvent.target.id!=="loadgallery" && clickEvent.target.id == 'gallthumb'){
var divImage = "<div id='gallerybig' style='z-index: 9999;display:grid; vertical-align:middle; position:absolute; left:0; top:0px; width:100vw; height: 100vh; background-color:black; visibility:visible;'><b style='color:white;z-index:55;float:left; margin-left:15px; margin-top:15px;'id='exit'>Close</b><img id='loadgallery'   src='"+clickEvent.target.src+"' style='background-color:lightgray; margin-top:10px; width:90vw; height:auto; max-height:90vh; display:block; margin:0 auto; float:center; z-index:4;'></img></div>";
var buff = document.getElementById('loadcontent').innerHTML;
document.getElementById('loadcontent').innerHTML = buff + divImage;
  }
  
if (clickEvent.target.id=="gallerybig" || clickEvent.target.id=="loadgallery" || clickEvent.target.id=="exit"){
document.getElementById("gallerybig").style.visibility='hidden';
const list = document.getElementById("loadgallery");

if (list.hasChildNodes()) {
  list.removeChild(list.children[0]);
}
 document.getElementById("loadgallery").remove();
 document.getElementById("gallerybig").remove();

}
}
`;
// https://stackoverflow.com/a/3978237/1066234
function strpos(haystack, needle, offset) 
{
    var i = (haystack+'').indexOf(needle, (offset || 0));
    return i === -1 ? false : i;
}

async function fetchAllPages(user, node=urlengine) {
	
	var PagesData = [];
	let elementcounter = 0;
  	const arrPages = await ExecQuery("array", "SELECT ID, Title, Active FROM Pages_"+user, "speccms","speccms", node);
  	var htmla, titlea;
  	let inactive = false;
	for (let id = 0; id < arrPages.length; ++id) {
	inactive = false;
	for (let index = 0; index < arrPages[0].length; ++index) {
		if (arrPages[id][index]['key'] == 'ID') { elementcounter =arrPages[id][index]['value']; } 
		if (arrPages[id][index]['key'] == 'Title') { titlea=arrPages[id][index]['value']; }
		if (arrPages[id][index]['key'] == 'Html') { htmla=atob(b64urldec(arrPages[id][index]['value'])); }
		if (arrPages[id][index]['key'] == 'Active') { if (arrPages[id][index]['value'] == 'false') { inactive = true; } }
	}
	if (inactive == false) {
	PagesData.push([elementcounter, titlea, ''])
	}
	elementcounter++;

	}
	return PagesData;
}

async function fetchPage(id, user, node=urlengine, type='scripts') {
	//const PagesDatab = [];
	const fetchHTMLtitle = await ExecQuery("single", "SELECT Title as t  FROM Pages_"+user +" WHERE ID == '"+id+"'", "speccms","speccms", node);
	const fetchHTMLhtml = await ExecQuery("single", "SELECT Html as ht FROM Pages_"+user+" WHERE ID == '"+id+"'", "speccms","speccms", node);
	var loadhtml =atob(b64urldec(fetchHTMLhtml));
	
if (type == 'scripts') {
	try {
	
	const arrScripts = get_substrings_between(loadhtml, '{script=', '}');
	const arrFiles = get_substrings_between(loadhtml, '{file=', '}');
	const arrFilesb64 = get_substrings_between(loadhtml, '{fileb64=', '}');
	const arrFilesGallery = get_substrings_between(loadhtml, '{gallery=', '}');
	const arrGalleryEnabled = get_substrings_between(loadhtml, '{enablegallery', '}');
	if (arrScripts.length > 0) {
		for (let i = 0; i < arrScripts.length; i++) {
		if (arrScripts[i] != "" && arrScripts[i] != 'null' ) {
		const script = await runScript(arrScripts[i], user, node);
		loadhtml = loadhtml.replace("{script="+arrScripts[i]+"}", "");
	}
	} }
	if (arrFiles.length > 0) {
	for (let i = 0; i < arrFiles.length; i++) {
		var hreffile = await getFile(arrFiles[i], 'speccms', 'speccms', node, 'link');
		loadhtml = loadhtml.replace("{file="+arrFiles[i]+"}", hreffile);
	}}
	if (arrFilesb64.length > 0) {
	for (let i = 0; i < arrFilesb64.length; i++) {
		var b64file = await getFile(arrFilesb64[i], 'speccms','speccms', node, 'contentb64');
		loadhtml = loadhtml.replace("{fileb64="+arrFilesb64[i]+"}", b64file);
	}}
	if (arrFilesGallery.length > 0) {
	for (let i = 0; i < arrFilesGallery.length; i++) {
		try {
		var b64file = await getFile(arrFilesGallery[i], 'speccms','speccms', node, 'contentb64');
	}
	catch {
		var b64file = "";
		loadhtml = loadhtml.replace("{gallery="+arrFilesGallery[i]+"}", "<div id='gallerythumbnail' align='center' valign='center' style='margin-left:3px; border-radius:2px; margin-top:3px;float:left;width:160px; height:160px; border: 1px solid black;display: grid;   vertical-align: middle;'>ID: "+(i+1)+"<br>Error</div>");
		
	}

		var imgGallery = "<div id='gallthumb' align='center' valign='center' style='margin-left:3px; border-radius:2px; margin-top:3px;float:left;width:160px; height:160px; border: 1px solid black;display: grid;   vertical-align: middle;'><img id='gallthumb' src='"+b64file+"' style=' border: 0px solid black; height:auto; max-height:156px; width:156px; display:block; margin: auto;'></img></div>";
		loadhtml = loadhtml.replace("{gallery="+arrFilesGallery[i]+"}", imgGallery);
	}
	loadhtml = loadhtml;
	}           
		if (arrFilesGallery.length > 0 || arrGalleryEnabled.length >0) { try {eval(scriptGalleryLargeIMG); } catch (e) { return(e.message);}  }
} catch {
	
}
}
	//PagesDatab.push(fetchHTMLtitle, loadhtml + "<b>a</b>");
  return loadhtml;
}



async function runScript(id, user, node=urlengine) {
  	const fetchHTMLid = await ExecQuery("single", "SELECT Html FROM Scripts_"+user + " WHERE ID == "+id, "speccms","speccms", node);
  	try {eval(atob(b64urldec(fetchHTMLid))); } catch (e) { return; } 
  	return atob(b64urldec(fetchHTMLid)) ;
}

async function getFileBB(id, user, node=urlengine, mode='download') {
	const filename = await ExecQuery("single", "SELECT Filename FROM Files_"+user + " WHERE ID == " +id, "speccms","speccms", node);
  	const fileb64 = await ExecQuery("single", "SELECT fileb64 FROM Files_"+user+ " WHERE ID == " +id, "speccms","speccms", node);
  	if (mode == 'download') {
	var adownload = document.createElement("a");
	adownload.href = b64urldec(fileb64);
    adownload.download = filename;
    document.body.appendChild(adownload);
    adownload.click();
	}
	if (mode == 'link') {
		return ("<a id='"+filename+"' href='"+b64urldec(fileb64)+"' download='"+filename+"'>"+filename+"</a>");
	}
	if (mode == 'contentb64') {
		return (b64urldec(fileb64));
	}
  	return b64urldec(fileb64);
}

async function getFile(id, user, password, node=urlengine, mode='download') {
	const filelist = await GetFileListB64(user, password, node);
	const filenameb = String(filelist[id]['value']);
	const filename = filenameb.slice(0, -4);
	const b64content = await RetrieveFileB64(filename, user, password, node);
	
	  	if (mode == 'download') {
	var adownload = document.createElement("a");
	adownload.href = b64content;
    adownload.download = filename;
    document.body.appendChild(adownload);
    adownload.click();
	}
	if (mode == 'link') {
		return ("<a id='"+filename+"' href='"+b64content+"' download='"+filename+"'>"+filename+"</a>");
	}
	if (mode == 'contentb64') {
		return b64content;
	}
  	return b64content;
}
async function delFile(id, user, password, node=urlengine, mode='download') {
	const filelist = await GetFileListB64(user, password, node);
	const filenameb = String(filelist[id]['value']);
	const filename = filenameb.slice(0, -4);
	
const xhr = new XMLHttpRequest();
var generatedurl = node+`?action=deletefileb64&user=`+user+`&password=`+password+`&filename=`+filename;
xhr.open('GET', generatedurl, true);
xhr.responseType = 'json';
    xhr.onload = function() {
      if (xhr.status === 200) {
         var pageData = xhr.response;
        return;
			//const jsonstring = JSON.stringify(xhr.response);
			//const jsonnew = JSON.parse(JSON.stringify(xhr.response));
      }
    };
    xhr.send();
   return;
}
async function loadCMSSettings(user, node=urlengine) {
	  	const arrPages = await ExecQuery("array", "SELECT * FROM Settings_"+user, "speccms","speccms", node);
		
		return arrPages;
}
async function loadCMSSetting(name, user, node=urlengine) {
		
		var settingvalue = await ExecQuery("single", "SELECT Value as val FROM 'Settings_"+user + "' WHERE Name == '" +name + "'", "speccms","speccms", node);
	
		return (settingvalue.toString());
}
module.exports = {
   fetchPage,
   runScript,
   fetchAllPages,
   getFile,
   delFile,
   loadCMSSetting,
   loadCMSSettings
}


