import logo from './assets/logo.svg';
import styles from './App.module.scss';
 import axios from "axios";
import { useEffect, useState, useMemo, useRef  } from "react";
import { slide as Menu } from 'react-burger-menu';
import Zoom from 'react-medium-image-zoom'

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Helmet } from 'react-helmet';

import "./sidebar.css";
import { Interpreter } from "eval5";
import jsonConfig from './config.json'

const parse = require('html-react-parser').default;

const {fetchAllPages, fetchPage, runScript, getFile, delFile, loadCMSSetting, loadCMSSettings} = require('speccmscontentb')
function App() {

	//erpotrans 
var urlengine = 'jsonengine.php';
//var urlengine = 'http://46.243.188.245/erpotrans/jsonengine.php';
//var urlengine = jsonConfig[0].engine;
var username = 'erpotrans';
var homeid = 1;

const [cmsButtons, setcmsButtons] = useState([]);
const [currPage, setcurrPage] = useState('Load');
const [pageHTML, setpageHTML] = useState('Laden gegevens..');
const [colorheader, setcolorheader] = useState('Load');
const [busyLoading, setbusyLoading] = useState(false);

const [headerheight, setheaderheight] = useState('Load');
const [footerheight, setfooterheight] = useState('Load');
const [colorfooter, setcolorfooter] = useState('Load');
const [colorcontent, setcolorcontent] = useState('Load');
const [textfooter, settextfooter] = useState("Load");
const [colormenubg, setcolormenubg] = useState("Load");
const [colorbuttons, setcolorbuttons] = useState("Load");
const [colormenubutton, setcolormenubutton] = useState("Load");
const [colormenubuttonhover, setcolormenubuttonhover] = useState("Load");
const [textcolorfooter, settextcolorfooter] = useState("Load");
const [textcolorbuttons, settextcolorbuttons] = useState("Load");
const [textcolor, settextcolor] = useState("Load");
const [pageLoaded, setpageLoaded] = useState('-1');
var colortest = "load";
const [isOpen, setOpen] = useState(false);
const [counter, setCounter] = useState(0);
   const handleIsOpen = () => {
    setOpen(!isOpen)
  }
const closeSideBar = () => {
    setOpen(false)
  }

async function specCMSinit(event) {
const allSettings = await loadCMSSettings(username, urlengine); 
await setcurrPage('Home');
setbusyLoading(true);
	for (let id = 0; id < allSettings.length; ++id) {
		var name = allSettings[id][1]['value'];
		var value = allSettings[id][2]['value'];

		if (name == 'headerheight') { setheaderheight(value); }
		if (name == 'footerheight') { setfooterheight(value); }
		if (name == 'colorheader') { setcolorheader(value); }
		if (name == 'colorfooter') { setcolorfooter(value); }
		if (name == 'colormain') { setcolorcontent(value); }
		if (name == 'textfooter') { settextfooter(value); }
		if (name == 'textcolorfooter') { settextcolorfooter(value); }
		if (name == 'colorbuttons') { setcolorbuttons(value); }
		if (name == 'textcolorbuttons') { settextcolorbuttons(value); }
		if (name == 'colormenubg') { setcolormenubg(value); }
		if (name == 'textcolor') { settextcolor(value); }
		if (name == 'colormenubutton') { setcolormenubutton(value); }
		if (name == 'colormenubuttonmouseover') { setcolormenubuttonhover(value); }
	} 


/*
await setcurrPage('Home');
await setheaderheight(await loadCMSSetting('headerheight', username, urlengine));  
await setfooterheight(await loadCMSSetting('footerheight', username, urlengine));  
await setcolorheader(await loadCMSSetting('colorheader', username, urlengine));  
await setcolorfooter(await loadCMSSetting('colorfooter', username, urlengine));
await setcolorcontent(await loadCMSSetting('colormain', username, urlengine));
await settextfooter(await loadCMSSetting('textfooter', username, urlengine));
await settextcolorfooter(await loadCMSSetting('textcolorfooter', username, urlengine));
await setcolorbuttons(await loadCMSSetting('colorbuttons', username, urlengine));
await settextcolorbuttons(await loadCMSSetting('textcolorbuttons', username, urlengine));
await setcolormenubg(await loadCMSSetting('colormenubg', username, urlengine));
await settextcolor(await loadCMSSetting('textcolor', username, urlengine));
await setcolormenubutton(await loadCMSSetting('colormenubutton', username, urlengine));
await setcolormenubuttonhover(await loadCMSSetting('colormenubuttonmouseover', username, urlengine));
* */
const testb = await fetchAllPages(username, urlengine); //0=id/1=title/2=html

await setcmsButtons(testb);

await specCMSloadPage(homeid);
setCounter(counter +1);

setbusyLoading(false);

return;
} 



const specCMSloadPage = async (id) => {
setbusyLoading(true);
if (pageLoaded !== id) {
document.getElementById("loadcontent").innerHTML = "";

setpageHTML('Laden pagina..');
const loadPageData = await fetchPage(id, username, urlengine, 'scripts'); //0=id/1=title/2=html
const html = await parse(loadPageData);
await setpageHTML(parse(loadPageData));
setCounter(counter+1);
await setpageLoaded(id);

return;
}
setbusyLoading(false);
return;
} 
const cmsButtonsHTML = cmsButtons.map((item, i) => {
var htmlsinglebutton = item[1].toString();
var idpage = item[0];
return (
		<div style={{overflow:'hidden'}}> 
			<b onClick={ async()  => { setOpen(false); await specCMSloadPage(idpage);}} style={{textAlign:'center', display:'inline-block', padding:'5px', fontSize:'13px', backgroundColor:colorbuttons, width:'200px', marginTop:'5px',textDecoration: 'none', color: textcolorbuttons, border: '1px solid black'}}>{htmlsinglebutton}</b>
		</div>
      );
});


if (currPage == 'Load') {
	
	
  (async (event) => {
    try {
	if (currPage == 'Load' ) { await specCMSinit(event);  } else {}
    } catch (err) {
      setpageHTML("Fout tijdens laden gegevens..")
    }
  })();
}

const CustomMenuIcon = () => <img src="./menu.svg" />;
    return (
		<>
		    {currPage != "Load" ? 
        <div style={{display:'block', color:textcolor,backgroundColor:colorcontent, overflow:'auto',  scrollbarWidth: 'thin' }}>
        <style>{`
/* Color/shape of burger icon bars */
.bm-burger-button {
  position: fixed;
  display:block;
  width: 36px;
  height: 30px;
  line-height:30px
  left: 26px;
top: calc( `+headerheight+` - (`+headerheight+`/2) - 15px);
vertical-align: middle;
} 

.bm-burger-bars {
  background: `+colormenubutton+`;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: `+colormenubuttonhover+`;
}
	.bm-menu {
	display: block;
	background: `+colormenubg+`;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
   overflow:hidden;
   z-index:500;
}`}
        </style>
         
        <Menu  isOpen={isOpen}
				onOpen={handleIsOpen}
				onClose={handleIsOpen}
				
				style={{ backgroundColor:colorheader, background:'red', position: 'fixed', height: '80px', lineHeight:'80px', overflow: 'hidden', zIndex:'1900'}}>
				<img src="./logomenu.png" style={{width: '210px', height:'33px'}}></img><br />
				{cmsButtonsHTML}
				<br />
				<p></p>
		</Menu>
		<div id="imglogo" style={{display:'grid', alignItems:'center', float:'left', position:'fixed', width: '100vw', backgroundColor:colorheader, height: headerheight, lineHeight:headerheight, right:'0px', top:'0px', left:'0px', zIndex:'2'}}><img src="./header.png" style={{right:'5',display:'table-cell',justifySelf:'end', width: 'auto', lineHeight:'99px', height:'43px', float:'right', marginTop:'0px', marginRight:'25px'}}></img></div> 
				
             <div id="maincontent" style={{ display:'block', backgroundColor:colorcontent,height:'auto', minHeight: 'calc(100vh -  80px)' , overflow:'hidden', scrollbarWidth:'thin',fontSize:'15px', textAlign: 'left', zIndex:'0' ,marginLeft:'45px' ,marginTop:'69px',marginRight:'45px'}}><br />
				
             {pageHTML != "" ? 
					<div style={{overflow:'hidden'}}>
					{pageHTML}
					<div style={{ marginTop:'99px',zIndex:'5555', height:'100%', justifyContent: 'center', alignItems: 'center', display:'flex'}}id="loadcontent"></div>
					</div>
					: "" } 
					
             </div>
                 
		
		<div id="footer" style={{display:'block', float:'right', position:'fixed', width: '100vw', backgroundColor:colorfooter, height: footerheight, lineHeight:footerheight, right:'0px', bottom:'0px', left:'0px', textAlign:'left',zIndex:'2', color:textcolorfooter}}><b>&nbsp; {parse(textfooter)}</b></div> 
        </div>
        : <div style={{display:'grid', alignItems:'center', justifyItems:'center', margin:'0 auto', height:'100vh', lineHeight:'100vh'}}><div style={{display: 'table-cell',verticalAlign:'middle', whiteSpace:'pre-wrap', wordWrap:'break-word'}} ><img style={{display:'block'}} src='loading.gif' alt='Loading'></img></div></div> }
        </>
        
    );
}

export default App;
